<template>
  <div class="mePlan">
    <h3>眼底相机</h3>

    <div class="tp">
      <UPfile :type="'FC'"></UPfile>
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          :on-change="sc"-->
<!--          :file-list="fileLists"-->
<!--          accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"-->
<!--          ref="uptp"-->
<!--          :auto-upload="false">-->
<!--&lt;!&ndash;        <i slot="default" class="el-icon-plus"></i>&ndash;&gt;-->
<!--        <div>上传图片</div>-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--&lt;!&ndash;        <span&ndash;&gt;-->
<!--&lt;!&ndash;            v-if="!disabled"&ndash;&gt;-->
<!--&lt;!&ndash;            class="el-upload-list__item-delete"&ndash;&gt;-->
<!--&lt;!&ndash;            @click="handleDownload(file)"&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="el-icon-download"></i>&ndash;&gt;-->
<!--&lt;!&ndash;        </span>&ndash;&gt;-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--        </div>-->
<!--      </el-upload>-->
<!--      <el-dialog :visible.sync="dialogVisible">-->
<!--        <img width="100%" :src="dialogImageUrl" alt="">-->
<!--      </el-dialog>-->
    </div>

    <div class="caozuo">
      <el-button type="info">AI分析</el-button>
      <el-button type="info" @click="dqwj">读取文件</el-button>
    </div>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.CL_RE_FC_Remark">
      </el-input>
    </div>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "eyeCamera",
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      textarea2: '',
      postData: {
        CL_RE_FC_ChooseId: "",
        CL_RE_FC_Remark: "",
        CL_RE_FC_isDone: true
      },
      fileLists: []
    };
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upEyeCamera', n)
      },
      deep: true
    },
    fileList: {
      handler(n, o) {
        //console.log(this.fileList)
      },
      deep: true
    }
  },
  created() {
    this.wjsc()

    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getEyeCamera()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length > 0) {
        this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
      }
    })

    this._api.publicApi.getImgList('FC')
    .then(res => {
      let arr = []
      if (res.GetListResult && res.GetListResult.length > 0) {
        for (let i = 0; i < res.GetListResult.length; i++) {
          arr.push({
            name: res.GetListResult[i].UFId,
            url: res.GetListResult[i].FullURL,
          })
        }
        this.fileLists = arr
      }
    })
  },
  methods: {
    handleRemove(file) {
      this._api.publicApi.deleImg(file.name)
      .then(res => {
        if (res.DeleteByIdReturn) {
          let upwj = this.$refs.uptp.uploadFiles
          for (let i = 0; i < upwj.length; i++) {
            if (upwj[i]['url'] == file.url) {
              upwj.splice(i, 1)
            }
          }
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },

    sc(file, f) {
      let _this = this
      let reader = new FileReader(), imgBase64 = '';
      reader.readAsDataURL(file.raw)
      reader.onload = function (e) {
        _this._http.post(pf.getUrl('UploadFile_Upload'), {
          "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
          },
          o: {
            FileBase64: e.currentTarget.result.split(',')[1],
            ExtensionName: ".png",
            UFSaveType: 'OSSPrivate',
            UFReObject: 'Choose',
            UFType: 'FC',
            UFReId: _this.$store.state.physicianVisits.xzConsulting
          }
        })
        .then(res => {
          if (res.Id) {
            file.name = res.Id
          }
        })
      }
    },

    dqwj() {
      // this._api.publicApi.getOCRType()
      // .then(res => {
      //   window.bound.selReport("29E58775", "邓密", "陈冠铭", "角膜地形图", res.GetResult.BDOCR_TempId, res.BaiduToken);
      // })

    },

    convertBase64UrlToBlob(urlData){
      let arr = urlData.split(','),
          bstr = window.atob(urlData), n = bstr.length, u8arr = new window.Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new window.Blob([u8arr], {type:'png'});
    },

    wjsc() {
      let _this = this
      window.getReportResult = function (res) {
        let upwj = _this.$refs.uptp.uploadFiles
        if (res.ReportImages && res.ReportImages.length) {
          for (let i = 0; i < res.ReportImages.length; i++) {
            let formData = new window.FormData() ;
            let blobDate = _this.convertBase64UrlToBlob( res.ReportImages[i].ImageBase64 );
            let url = window.URL.createObjectURL(blobDate)
            let stri = pf.randomString(6)+".png"
            formData.append(stri, blobDate); // 文件对象
            _this._http.post(pf.getUrl('UploadFile_Upload'), {
              "token": {
                "TimeSpan": 1566827733,
                "sToken": "768DCAFFF869CD61372790C2738012EC"
              },
              o: {
                FileBase64:  res.ReportImages[i].ImageBase64,
                ExtensionName: "."+formData.get(stri).type,
                UFSaveType: 'OSSPrivate',
                UFReObject: "choose",
                UFType: 'FC',
                UFReId: _this.$store.state.physicianVisits.xzConsulting
              }
            })
                .then(r => {
                  if (r.Id) {
                    upwj.push({
                      name: r.Id,
                      raw: formData.get(stri),
                      status: "ready",
                      percentage: 0,
                      size: formData.get(stri).size,
                      uid: pf.randomString(18),
                      url
                    })
                  }
                })
          }
        }
      }
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped>
.mePlan {
  width: 90%;
  margin: 0 auto;
  padding-top: 0.3rem;
}
.tp {
  display: flex;
  justify-content: start;
}
.bz {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 30px;
}
.bz p {padding-bottom: 10px;font-size: 17px}
h3 {
  padding-bottom: 0.3rem;
}
.caozuo {
  text-align: left;
  padding-top: 0.3rem;
}
</style>
